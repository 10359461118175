@use '@angular/material' as mat;
@import "palette";
@include mat.core();
/* You can add global styles to this file, and also import other style files */

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
//$custom-typography: mat-typography-config($font-family: '"Inter", sans-serif;');
$custom-typography: mat.define-typography-config(
  $font-family: 'Inter, sans-serif'
);
@include mat.all-component-typographies($custom-typography);

$my-primary: mat.define-palette($reulity-palette, 400);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  )
));

@include mat.all-component-themes($my-theme);

html, body {
  height: 100%;
}

body {
  font-size: 30px;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

.mat-button-toggle-label-content {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: .5em !important;
}

.mat-raised-button {
  padding: 0.5rem 1rem !important;
}

/*maps autocomplete*/
.pac-item {
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
  font-weight: 300 !important;
  color: rgb(33, 33, 33) !important;
  line-height: 40px !important;
  /*font-weight: 800;*/
}

.pac-item:hover {
  background-color: #eeeeee !important;
  transition: background .15s linear;
}

.pac-container {
  color: rgb(33, 33, 33) !important;
  background-color: #fafafa;
  /*font-weight: 800;*/
}

.pac-icon, .pac-icon-marker {
  background: none !important;
  background-image: none !important;
}
